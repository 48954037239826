<template>
  <div>
    <div class="logo">
      <svg class="logo">
        <use xlink:href="@/assets/svg/icons.svg#enefit"></use>
      </svg>
    </div>

    <span class="logo-text">Enefit OCPP Central System</span>

    <div class="btn login-button" v-on:click="login()">
      <img alt="login" src="@/assets/svg/microsoft.svg" />
    </div>

    <div class="error" v-if="this.error != null">
      <span>Error! {{ errorDescription }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "loginView",
  props: ["error"],
  data() {
    return {
      errorDescription: null,
      url: `${process.env.VUE_APP_AUTHORITY}`,
      clientId: `client_id=${process.env.VUE_APP_CLIENT_ID}`,
      responseType: `response_type=${process.env.VUE_APP_RESPONSE_TYPE}`,
      redirectUrl: `redirect_uri=${process.env.VUE_APP_REDIRECT_URI}`,
      responseMode: `response_mode=${process.env.VUE_APP_RESPONSE_MODE}`,
      scope: `scope=${process.env.VUE_APP_SCOPE}`,
      state: `state=${process.env.VUE_APP_STATE}`,
    };
  },
  methods: {
    login() {
      const vm = this;
      window.location.assign(
        `${vm.url}?${vm.clientId}&${vm.responseType}&${vm.redirectUrl}&${vm.responseMode}&${vm.scope}&${vm.state}`
      );
    },
    processError() {
      const params = new URLSearchParams(this.error);
      if (params.get("error_description") !== null) {
        this.errorDescription = params.get("error_description");
      } else {
        this.errorDescription = this.error;
      }
    },
  },
  beforeMount() {
    if (localStorage.getItem("jwt") !== null) {
      this.$router.push("/ui");
    }

    if (this.error !== null) {
      this.processError();
    }
  },
};
</script>
<style>
.logo {
  fill: #0fac5f;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
}

.logo-text {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  font-weight: 700;
}

.login-button {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
}

.error {
  color: red;
  font-family: monospace;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 50px;
  padding-left: 300px;
  padding-right: 300px;
}
</style>
